import React, { useState, useContext, useRef, useEffect } from "react"
import { WhiteLabelContext } from "react-whitelabel"
import { Carousel } from "react-responsive-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Navbar from "react-bootstrap/Navbar"
import Modal from "react-bootstrap/Modal"
import { useHistory, Link } from "react-router-dom"
import { Nav } from "react-bootstrap"
import ReCAPTCHA from "react-google-recaptcha"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { routePaths } from "../../routes/routes"
import { useFormik } from "formik"
import * as Yup from "yup"
import emailjs from "@emailjs/browser"
import NewSearchForm from "../SearchForm/NewSearchForm.jsx"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import "./IndexPage.scss"
import ContactForm from "../ContactForm/ContactForm.jsx"
import FaqListing from "../FaqListing/FaqListing.jsx"
import SocialLinks from "../SocialLinks/SocialLinks.jsx"

import GoogleMap from "../GoogleMap/GoogleMap.jsx"

const IndexPage = props => {
  let history = useHistory()
  const label = useContext(WhiteLabelContext)
  const { searchFormTitle, searchFormSubtitle, searchFormDescription } =
    useContext(WhiteLabelContext)
  const [expanded, setExpanded] = useState(false)
  const [modalShown, setModalShown] = useState(false)
  const [email, setEmail] = useState("")
  const [formError, setFormError] = useState(false)
  const [discountModal, setDiscountModal] = useState(false)
  const [promoCodeAPISuccess, setPromoCodeAPISuccess] = useState(false)
  const [showText, setShowText] = useState(false)
  const [isActive, setIsActive] = useState(false)

  var phoneRegEx = /^[\d\(\)\+]+$/
  const form = useRef()
  const clickToBookingPage = () => {
    history.push(routePaths.root)
  }

  const openIntercomeLiveChatWindow = event => {
    event.preventDefault()
    var intercomeIcon = document.getElementsByClassName("intercom-launcher")[0]
    if (intercomeIcon) {
      intercomeIcon.click()
    }
  }

  // get a quote form validation
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      date: "",
      noofpassengers: "",
      citylocation: "",
      occasionnotes: "",
      gcaptcha: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      // date: Yup.date('Invalid Date').required('Date is required'),
      phone: Yup.string()
        .matches(phoneRegEx, "please enter valid phone number")
        .min(9, "Phone must be min 9 digits")
        .required("Phone number is required"),
      noofpassengers: Yup.string().required("No. of Passengers is required"),
      citylocation: Yup.string()
        .max(15, "City/Location cannot be more than 15 char")
        .required("City/Location required"),
      occasionnotes: Yup.string().max(
        500,
        "Occasion notes cannot be more than 500 char"
      ),
      gcaptcha: Yup.string("Captcha is expired")
        .typeError("Captcha is expired")
        .required("Captcha is required"),
    }),
    onSubmit: values => {
      emailjs
        .sendForm(
          "service_p6dvwdj",
          "template_5m7pyiq",
          form.current,
          "2CC3n-4BQ-cOrf3jv"
        )
        .then(
          function (response) {
            history.push(routePaths.thankyou)
          },
          function (err) {
            console.log("FAILED...", err)
          }
        )
    },
  })

  const clickContact = () => {
    setExpanded(false)
    history.push(routePaths.contact)
  }

  const clickHome = () => {
    setExpanded(false)
    history.push(routePaths.root)
  }

  const clickTerms = () => {
    setExpanded(false)
    history.push(routePaths.siteTerms)
  }

  const clickPrivacy = () => {
    setExpanded(false)
    history.push(routePaths.privacyPolicy)
  }

  const handleSetRecaptcha = captchaData => {
    formik.setFieldValue("gcaptcha", captchaData)
  }

  const hideRequestFormToggle = () => {
    setModalShown(!modalShown)
  }

  const closeModal = () => {
    setDiscountModal(false)
    setShowText(true)
    setIsActive(true)
  }

  const btnContainer = () => {
    return (
      <div className="btn_container">
        <div className="btn_inner">
          <div className="new-banner-btn-container desktop">
            <div className="cta-buttons-wrapper">
              <div className="banner-call-to-action-buttons">
                <a href="tel:8772419318" className="banner-call-for-discount">
                  <span className="discount-text">
                    Call 877-241-9318 for Sales
                  </span>
                </a>
              </div>
              <div className="banner-call-to-action-buttons">
                <a className="book-online-now" onClick={hideRequestFormToggle}>
                  <span className="book-online-now">Request a Quote</span>
                </a>
              </div>
              <div className="banner-call-to-action-buttons">
                <a
                  className="book-online-now"
                  onClick={openIntercomeLiveChatWindow}
                >
                  <span className="book-online-now">Live Chat with Us</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const btnContainerMobile = () => {
    return (
      <div className="new-banner-btn-container mobile">
        <div className="cta-buttons-wrapper">
          <div className="banner-call-to-action-buttons">
            <a href="tel:8772419318" className="banner-call-for-discount">
              <span className="discount-text">Call 877-241-9318 for Sales</span>
            </a>
          </div>
          <div className="banner-call-to-action-buttons">
            <a className="book-online-now" onClick={hideRequestFormToggle}>
              <span className="book-online-now">Request a Quote</span>
            </a>
          </div>
        </div>
      </div>
    )
  }

  const specializeSection = () => {
    return (
      <div className="specialize-section">
        <h3 className="specialize-section-heading">We Also Specialize In</h3>
        <div className="specialize-section-image-container">
          <img
            src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Luxury-Fleet-Vehicle.png"
            style={{ height: "170px" }}
            alt="Luxury-Fleet-Vehicle.png"
          />
        </div>

        <div className="specialize-container">
          <ul>
            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Multi-day-events.jpg"
                alt="Multi-day-events.jpg"
              />
              <p>
                Multi-Day-Events, <br /> Multi-Vehicle Events
              </p>
            </li>

            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Large-scale-events.jpg"
                alt="Large-scale-events.jpg"
              />
              <p>
                Large Scale Events, <br /> Conventions, Conferences
              </p>
            </li>

            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Sporting-events.jpg"
                alt="Sporting-events.jpg"
              />
              <p>Sporting Events</p>
            </li>

            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Commuters-schools.jpg"
                alt="Commuters-schools.jpg"
              />
              <p>Commuter & Schools</p>
            </li>

            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Airport-transfers.jpg"
                alt="Airport-transfers.jpg"
              />
              <p>Airport Transfers</p>
            </li>

            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Strike-transportation-disaster-relief.jpg"
                alt="Strike-transportation-disaster-relief.jpg"
              />
              <p>Strike & Disaster Relief</p>
            </li>

            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Marathons.jpg"
                alt="Strike-transportation-disaster-relief.jpg"
              />
              <p>Marathons</p>
            </li>

            <li>
              <img
                src=" https://assets.s3.amazonaws.com/images/front-end/new-landing/VIP-events-team-building.jpg"
                alt="VIP-events-team-building.jpg"
              />
              <p>
                VIP Special Events <br /> & Team Building
              </p>
            </li>
          </ul>
        </div>
        <div className="request-a-quote-btn-container">
          <button
            className="request-a-quote-btn"
            onClick={hideRequestFormToggle}
          >
            <span className="request-a-quote">📋 Request a Custom Quote</span>
          </button>
        </div>
      </div>
    )
  }

  const newSpecializeSection = () => {
    return (
      <div className="new-specialize-section">
        {/* <div className="specialize-container-heading">
          <h3 className="specialize-section-heading">SPECIALIZING IN</h3>
        </div> */}
        <div className="specialize-container-slider">
          <logo-slider>
            <div className="specialize-container">
              <span>
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/PNGs/multi-day-events.png"
                  alt="multi-day-events.png"
                />
                <p>Multi-Day-Events</p>
              </span>

              <span>
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/PNGs/large-events-festivals.png"
                  alt="large-events-festivals.png"
                />
                <p>Large Scale Events</p>
              </span>

              {/* <span>
                <img src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Sporting-events.jpg" />
                <p>Sporting Events</p>
              </span> */}

              <span>
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/PNGs/commuter-school-services.png"
                  alt="commuter-school-services.png"
                />
                <p>Commuter & Schools</p>
              </span>

              <span>
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/PNGs/Airport-transfer-service.png"
                  alt="Airport-transfer-service.png"
                />
                <p>Airport Transfers</p>
              </span>

              {/* <span>
                <img src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Strike-transportation-disaster-relief.jpg" />
                <p>Strike & Disaster Relief</p>
              </span> */}

              {/* <span>
                <img src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Marathons.jpg" />
                <p>Marathons</p>
              </span> */}

              <span>
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/PNGs/vip-exclusive-events.png"
                  alt="vip-exclusive-events.png"
                />
                <p>VIP Special Events</p>
              </span>
            </div>
          </logo-slider>
        </div>
      </div>
    )
  }

  const fourFeatures = () => {
    return (
      <section className="four-features">
        <div className="container">
          <ul>
            <li>
              <img
                src="https://limos-frontend.s3.amazonaws.com/frontend/CustomerService.jpg"
                alt="CustomerService.jpg"
              />
              <p>
                Priority 24/7 <br /> Customer Support
              </p>
            </li>
            <li>
              <img
                src="https://limos-frontend.s3.amazonaws.com/frontend/BusinessTravel.jpg"
                alt="BusinessTravel.jpg"
              />
              <p>
                Worldwide Travel <br /> Solutions
              </p>
            </li>
            <li>
              <img
                src="https://limos-frontend.s3.amazonaws.com/frontend/LuxuryVehicles.jpg"
                alt="LuxuryVehicles.jpg"
              />
              <p>
                Luxury Sedans to <br /> Motorcoaches
              </p>
            </li>
            <li>
              <img
                src="https://limos-frontend.s3.amazonaws.com/frontend/professional-chauffeur.png"
                alt="professional-chauffeur.png"
              />
              <p>
                Professional Certified <br /> Chauffeurs
              </p>
            </li>
            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/DutyofCare.jpg"
                alt="new-landing/DutyofCare.jpg"
              />
              <p>
                Trust and <br /> Safety
              </p>
            </li>
          </ul>
        </div>
      </section>
    )
  }

  const reviewSection = () => {
    return (
      <>
        <ContactForm />
        <div className="review-main-content">
          <h2>Over 1,797+ reviews</h2>

          <div
            className="yotpo-popup-box-small yotpo-nav yotpo-nav-primary "
            role="tablist"
          >
            <ul className="">
              <li
                className="yotpo-nav-tab yotpo-active"
                data-type="site"
                role="tab"
                tabIndex="0"
                aria-selected="true"
                data-content="yotpo-testimonials-site-reviews yotpo-testimonials-site-bottomline yotpo-site-reviews-tab"
                aria-controls="yotpo-testimonials-site-reviews yotpo-testimonials-site-bottomline yotpo-site-reviews-tab"
              >
                <div className="yotpo-nav-wrapper">
                  <span className=""> Site Reviews </span>
                </div>
              </li>
            </ul>
          </div>

          <div className="yotpo">
            <div className="yotpo-review yotpo-regular-box">
              <div className="yotpo-header yotpo-verified-buyer ">
                <span className="yotpo-icon-profile yotpo-header-element pull-left">
                  <span className="yotpo-user-letter">T</span>
                  <span
                    className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    data-target-container="yotpo-header"
                  ></span>
                </span>
                <div className="yotpo-header-element ">
                  <span
                    className="y-label yotpo-user-name yotpo-font-bold pull-left"
                    aria-level="3"
                  >
                    Thomas D.
                  </span>
                  <div className="label-with-tooltip pull-left" aria-level="3">
                    <span
                      className="y-label yotpo-user-title yotpo-action-hover"
                      data-type="toggleFade"
                      data-target="yotpo-tool-tip"
                      aria-describedby="274825440"
                    >
                      Verified Buyer
                    </span>
                  </div>
                  <div className="yotpo-clr"></div>
                  <div className="yotpo-review-stars">
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                  </div>
                  <div className="yotpo-clr"></div>
                </div>
                <div className="yotpo-header-element yotpo-header-actions ">
                  <span className="y-label yotpo-review-date">06/05/23</span>
                </div>
              </div>
              <div className="yotpo-main ">
                <div
                  className="content-title yotpo-font-bold"
                  role="heading"
                  aria-level="3"
                >
                  Safe and luxurious!
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-wrapper">
                  <div className="content-review">
                    Our travel too and from the airport was on time, courteous,
                    safe and comfortable.
                  </div>
                </div>
                {/*  <a
                className="product-link-wrapper "
                aria-label="Product http://limos.com"
                aria-hidden="false"
                tabIndex="0"
                href="https://yotpo.com/go/jN5PI8iS"
                target="_blank"
                rel="noreferrer"
              >
                <div className="y-label product-link">On https://limos.com</div>
              </a> */}
              </div>
              <div className="yotpo-footer ">
                {/*  <div className="footer-actions">
                <span
                  className="yotpo-action"
                  data-type="toggleFade"
                  data-target="share-review"
                  role="button"
                  tabIndex="0"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon="share-square" />
                  <span className="y-label">share</span>
                  <span className="yotpo-icon yotpo-icon-seperator"></span>
                </span>
              </div>

              <div className="yotpo-helpful" role="group">
                <span className="y-label label-helpful">
                  Was this review helpful?
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="up"
                  role="button"
                  aria-label="vote up Review by Thomas D. on  4 Mar 2021"
                  tabIndex="0"
                >
                  <FontAwesomeIcon icon="thumbs-up" />
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="up"
                >
                  2
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="down"
                  role="button"
                  aria-label="vote down Review by Thomas D. on  4 Mar 2021"
                  tabIndex="0"
                >
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="down"
                >
                  0
                </span>
              </div> */}
              </div>
            </div>
          </div>

          <div className="yotpo">
            <div className="yotpo-review yotpo-regular-box">
              <div className="yotpo-header yotpo-verified-buyer ">
                <span className="yotpo-icon-profile yotpo-header-element pull-left">
                  <span className="yotpo-user-letter">J</span>
                  <span
                    className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    data-target-container="yotpo-header"
                  ></span>
                </span>
                <div className="yotpo-header-element ">
                  <span className="y-label yotpo-user-name yotpo-font-bold pull-left">
                    Jonathan F
                  </span>
                  <div className="label-with-tooltip pull-left">
                    <span
                      className="y-label yotpo-user-title yotpo-action-hover"
                      data-type="toggleFade"
                      data-target="yotpo-tool-tip"
                      aria-describedby="274825440"
                    >
                      Verified Buyer
                    </span>
                  </div>
                  <div className="yotpo-clr"></div>
                  <div className="yotpo-review-stars ">
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                  </div>
                  <div className="yotpo-clr"></div>
                </div>
                <div className="yotpo-header-element yotpo-header-actions ">
                  <span className="y-label yotpo-review-date"> 05/07/23</span>
                </div>
              </div>
              <div className="yotpo-main ">
                <div
                  className="content-title yotpo-font-bold"
                  role="heading"
                  aria-level="3"
                >
                  Great!
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-wrapper">
                  <div className="content-review">Great</div>
                </div>
                {/*  <a
                className="product-link-wrapper"
                tabIndex="0"
                href="https://yotpo.com/go/jN5PI8iS"
                target="_blank"
                rel="noreferrer"
              >
                <div className="y-label product-link">On https://limos.com</div>
              </a> */}
              </div>
              <div className="yotpo-footer ">
                {/*     <div className="footer-actions">
                <span
                  className="yotpo-action"
                  data-type="toggleFade"
                  data-target="share-review"
                  role="button"
                  tabIndex="0"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon="share-square" />
                  <span className="y-label">share</span>
                  <span className="yotpo-icon yotpo-icon-seperator"></span>
                </span>
              </div>

              <div className="yotpo-helpful" role="group">
                <span className="y-label label-helpful">
                  Was this review helpful?
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="up"
                  role="button"
                  aria-label="vote up Review by Thomas D. on  4 Mar 2021"
                  tabIndex="0"
                >
                  <FontAwesomeIcon icon="thumbs-up" />
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="up"
                >
                  2
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="down"
                  role="button"
                  aria-label="vote down Review by Thomas D. on  4 Mar 2021"
                  tabIndex="0"
                >
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="down"
                >
                  0
                </span>
              </div> */}
              </div>
            </div>
          </div>

          <div className="yotpo">
            <div className="yotpo-review yotpo-regular-box">
              <div className="yotpo-header yotpo-verified-buyer ">
                <span className="yotpo-icon-profile yotpo-header-element pull-left">
                  <span className="yotpo-user-letter">P</span>
                  <span
                    className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    data-target-container="yotpo-header"
                  ></span>
                </span>
                <div className="yotpo-header-element ">
                  <span
                    className="y-label yotpo-user-name yotpo-font-bold pull-left"
                    aria-level="3"
                  >
                    Patricia L.
                  </span>
                  <div className="label-with-tooltip pull-left" aria-level="3">
                    <span
                      className="y-label yotpo-user-title yotpo-action-hover"
                      data-type="toggleFade"
                      data-target="yotpo-tool-tip"
                      aria-describedby="274825440"
                    >
                      Verified Buyer
                    </span>
                  </div>
                  <div className="yotpo-clr"></div>
                  <div className="yotpo-review-stars">
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                  </div>
                  <div className="yotpo-clr"></div>
                </div>
                <div className="yotpo-header-element yotpo-header-actions ">
                  <span className="y-label yotpo-review-date">04/27/23</span>
                </div>
              </div>
              <div className="yotpo-main ">
                <div
                  className="content-title yotpo-font-bold"
                  role="heading"
                  aria-level="3"
                >
                  We’ve had great experiences with
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-wrapper">
                  <div className="content-review">
                    We’ve had great experiences with limos.com and will continue
                    to use them in the future. Always on time and courteous
                    drivers!
                  </div>
                </div>
                {/*  <a
                className="product-link-wrapper "
                aria-label="Product http://limos.com"
                aria-hidden="false"
                tabIndex="0"
                href="https://yotpo.com/go/jN5PI8iS"
                target="_blank"
                rel="noreferrer"
              >
                <div className="y-label product-link">On https://limos.com</div>
              </a> */}
              </div>
              <div className="yotpo-footer ">
                {/* <div className="footer-actions">
                <span
                  className="yotpo-action"
                  data-type="toggleFade"
                  data-target="share-review"
                  role="button"
                  tabIndex="0"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon="share-square" />
                  <span className="y-label">share</span>
                  <span className="yotpo-icon yotpo-icon-seperator"></span>
                </span>
              </div>

              <div className="yotpo-helpful" role="group">
                <span className="y-label label-helpful">
                  Was this review helpful?
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="up"
                  role="button"
                  tabIndex="0"
                >
                  <FontAwesomeIcon icon="thumbs-up" />
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="up"
                >
                  1
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="down"
                  role="button"
                  tabIndex="0"
                >
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="down"
                >
                  0
                </span>
              </div> */}
              </div>
            </div>
          </div>

          <div className="yotpo">
            <div className="yotpo-review yotpo-regular-box">
              <div className="yotpo-header yotpo-verified-buyer ">
                <span className="yotpo-icon-profile yotpo-header-element pull-left">
                  <span className="yotpo-user-letter">T</span>
                  <span
                    className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    data-target-container="yotpo-header"
                  ></span>
                </span>
                <div className="yotpo-header-element ">
                  <span
                    className="y-label yotpo-user-name yotpo-font-bold pull-left"
                    aria-level="3"
                  >
                    Terry B.
                  </span>
                  <div className="label-with-tooltip pull-left" aria-level="3">
                    <span
                      className="y-label yotpo-user-title yotpo-action-hover"
                      data-type="toggleFade"
                      data-target="yotpo-tool-tip"
                      aria-describedby="274825440"
                    >
                      Verified Buyer
                    </span>
                  </div>
                  <div className="yotpo-clr"></div>
                  <div className="yotpo-review-stars">
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                  </div>
                  <div className="yotpo-clr"></div>
                </div>
                <div className="yotpo-header-element yotpo-header-actions ">
                  <span
                    className="y-label yotpo-review-date"
                    aria-label="review date 04/27/23"
                  >
                    04/18/23
                  </span>
                </div>
              </div>
              <div className="yotpo-main ">
                <div
                  className="content-title yotpo-font-bold"
                  role="heading"
                  aria-level="3"
                >
                  I have used this driver
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-wrapper">
                  <div className="content-review">
                    I have used this driver a few times. He is very nice, always
                    on time and very personable. We hope we can continue to use
                    him for all our airport transports.
                  </div>
                </div>
                {/* <a
                className="product-link-wrapper "
                aria-label="Product http://limos.com"
                aria-hidden="false"
                tabIndex="0"
                href="https://yotpo.com/go/jN5PI8iS"
                target="_blank"
                rel="noreferrer"
              >
                <div className="y-label product-link">On https://limos.com</div>
              </a> */}
              </div>
              <div className="yotpo-footer ">
                {/*     <div className="footer-actions">
                <span
                  className="yotpo-action"
                  data-type="toggleFade"
                  data-target="share-review"
                  role="button"
                  tabIndex="0"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon="share-square" />
                  <span className="y-label">share</span>
                  <span className="yotpo-icon yotpo-icon-seperator"></span>
                </span>
              </div>

              <div className="yotpo-helpful" role="group">
                <span className="y-label label-helpful">
                  Was this review helpful?
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="up"
                  role="button"
                  tabIndex="0"
                >
                  <FontAwesomeIcon icon="thumbs-up" />
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="up"
                >
                  2
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="down"
                  role="button"
                  tabIndex="0"
                >
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="down"
                >
                  0
                </span>
              </div> */}
              </div>
            </div>
          </div>

          <div className="yotpo">
            <div className="yotpo-review yotpo-regular-box">
              <div className="yotpo-header yotpo-verified-buyer ">
                <span className="yotpo-icon-profile yotpo-header-element pull-left">
                  <span className="yotpo-user-letter">C</span>
                  <span
                    className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    data-target-container="yotpo-header"
                  ></span>
                </span>
                <div className="yotpo-header-element ">
                  <span
                    className="y-label yotpo-user-name yotpo-font-bold pull-left"
                    aria-level="3"
                  >
                    Carla L.
                  </span>
                  <div className="label-with-tooltip pull-left" aria-level="3">
                    <span
                      className="y-label yotpo-user-title yotpo-action-hover"
                      data-type="toggleFade"
                      data-target="yotpo-tool-tip"
                      aria-describedby="274825440"
                    >
                      Verified Buyer
                    </span>
                  </div>
                  <div className="yotpo-clr"></div>
                  <div className="yotpo-review-stars">
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                  </div>
                  <div className="yotpo-clr"></div>
                </div>
                <div className="yotpo-header-element yotpo-header-actions ">
                  <span className="y-label yotpo-review-date">01/13/23</span>
                </div>
              </div>
              <div className="yotpo-main ">
                <div
                  className="content-title yotpo-font-bold"
                  role="heading"
                  aria-level="3"
                >
                  Ephraim is the FINEST, most
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-wrapper">
                  <div className="content-review">
                    Ephraim is the FINEST, most careful and kind Driver. EVER.
                    (Well, Milton is too). These two Gentlemen have been driving
                    for me for about 4 years. They both are true and trusted
                    Professionals.
                  </div>
                </div>
                {/*  <a
                className="product-link-wrapper "
                aria-label="Product http://limos.com"
                aria-hidden="false"
                tabIndex="0"
                href="https://yotpo.com/go/jN5PI8iS"
                target="_blank"
                rel="noreferrer"
              >
                <div className="y-label product-link">On https://limos.com</div>
              </a> */}
              </div>
              <div className="yotpo-footer ">
                {/*     <div className="footer-actions">
                <span
                  className="yotpo-action"
                  data-type="toggleFade"
                  data-target="share-review"
                  role="button"
                  tabIndex="0"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon="share-square" />
                  <span className="y-label">share</span>
                  <span className="yotpo-icon yotpo-icon-seperator"></span>
                </span>
              </div>

              <div className="yotpo-helpful" role="group">
                <span className="y-label label-helpful">
                  Was this review helpful?
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="up"
                  role="button"
                  tabIndex="0"
                >
                  <FontAwesomeIcon icon="thumbs-up" />
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="up"
                >
                  2
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="down"
                  role="button"
                  tabIndex="0"
                >
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="down"
                >
                  0
                </span>
              </div> */}
              </div>
            </div>
          </div>

          <div className="yotpo">
            <div className="yotpo-review yotpo-regular-box">
              <div className="yotpo-header yotpo-verified-buyer ">
                <span className="yotpo-icon-profile yotpo-header-element pull-left">
                  <span className="yotpo-user-letter">W</span>
                  <span
                    className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    data-target-container="yotpo-header"
                  ></span>
                </span>
                <div className="yotpo-header-element ">
                  <span
                    className="y-label yotpo-user-name yotpo-font-bold pull-left"
                    aria-level="3"
                  >
                    Wei O.
                  </span>
                  <div className="label-with-tooltip pull-left" aria-level="3">
                    <span
                      className="y-label yotpo-user-title yotpo-action-hover"
                      data-type="toggleFade"
                      data-target="yotpo-tool-tip"
                      aria-describedby="274825440"
                    >
                      Verified Buyer
                    </span>
                  </div>
                  <div className="yotpo-clr"></div>
                  <div className="yotpo-review-stars">
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                  </div>
                  <div className="yotpo-clr"></div>
                </div>
                <div className="yotpo-header-element yotpo-header-actions ">
                  <span className="y-label yotpo-review-date">03/23/23</span>
                </div>
              </div>
              <div className="yotpo-main ">
                <div
                  className="content-title yotpo-font-bold"
                  role="heading"
                  aria-level="3"
                >
                  Great experience!
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-wrapper">
                  <div className="content-review">
                    Allan was an amazing driver! He was patient and professional
                    all the way through. The booking process on the website was
                    also seamless and fuss-free.
                  </div>
                </div>
                {/*  <a
                className="product-link-wrapper "
                aria-label="Product http://limos.com"
                aria-hidden="false"
                tabIndex="0"
                href="https://yotpo.com/go/jN5PI8iS"
                target="_blank"
                rel="noreferrer"
              >
                <div className="y-label product-link">On https://limos.com</div>
              </a> */}
              </div>
              <div className="yotpo-footer ">
                {/*     <div className="footer-actions">
                <span
                  className="yotpo-action"
                  data-type="toggleFade"
                  data-target="share-review"
                  role="button"
                  tabIndex="0"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon="share-square" />
                  <span className="y-label">share</span>
                  <span className="yotpo-icon yotpo-icon-seperator"></span>
                </span>
              </div>

              <div className="yotpo-helpful" role="group">
                <span className="y-label label-helpful">
                  Was this review helpful?
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="up"
                  role="button"
                  tabIndex="0"
                >
                  <FontAwesomeIcon icon="thumbs-up" />
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="up"
                >
                  0
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="down"
                  role="button"
                  tabIndex="0"
                >
                 
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="down"
                >
                  0
                </span>
              </div> */}
              </div>
            </div>
          </div>

          <div className="yotpo">
            <div className="yotpo-review yotpo-regular-box">
              <div className="yotpo-header yotpo-verified-buyer ">
                <span className="yotpo-icon-profile yotpo-header-element pull-left">
                  <span className="yotpo-user-letter">A</span>
                  <span
                    className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    data-target-container="yotpo-header"
                  ></span>
                </span>
                <div className="yotpo-header-element ">
                  <span
                    className="y-label yotpo-user-name yotpo-font-bold pull-left"
                    aria-level="3"
                  >
                    Arturo P.
                  </span>
                  <div className="label-with-tooltip pull-left" aria-level="3">
                    <span
                      className="y-label yotpo-user-title yotpo-action-hover"
                      data-type="toggleFade"
                      data-target="yotpo-tool-tip"
                      aria-describedby="274825440"
                    >
                      Verified Buyer
                    </span>
                  </div>
                  <div className="yotpo-clr"></div>
                  <div className="yotpo-review-stars">
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                    <FontAwesomeIcon icon="star" className="star-icon" />
                  </div>
                  <div className="yotpo-clr"></div>
                </div>
                <div className="yotpo-header-element yotpo-header-actions ">
                  <span className="y-label yotpo-review-date">04/25/23</span>
                </div>
              </div>
              <div className="yotpo-main ">
                <div
                  className="content-title yotpo-font-bold"
                  role="heading"
                  aria-level="3"
                >
                  Great service
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-wrapper">
                  <div className="content-review">
                    Driver was on time and courteous. Great driving. Vehicle was
                    nice and clean. Great experience overall
                  </div>
                </div>
                {/*    <a
                className="product-link-wrapper"
                aria-label="Product http://limos.com"
                aria-hidden="false"
                tabIndex="0"
                href="https://yotpo.com/go/jN5PI8iS"
                target="_blank"
                rel="noreferrer"
              >
                <div className="y-label product-link">On https://limos.com</div>
              </a> */}
              </div>
              <div className="yotpo-footer ">
                {/*  <div className="footer-actions">
                <span
                  className="yotpo-action"
                  data-type="toggleFade"
                  data-target="share-review"
                  role="button"
                  tabIndex="0"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon="share-square" />
                  <span className="y-label">share</span>
                  <span className="yotpo-icon yotpo-icon-seperator"></span>
                </span>
              </div>

              <div className="yotpo-helpful" role="group">
                <span className="y-label label-helpful">
                  Was this review helpful?
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="up"
                  role="button"
                  tabIndex="0"
                >
                  <FontAwesomeIcon icon="thumbs-up" />
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="up"
                >
                  0
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="down"
                  role="button"
                  tabIndex="0"
                >
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="down"
                >
                  0
                </span>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const rioSection = () => {
    return (
      <div className="rio-section-container">
        <div className="rio-section-title">
          <h2> How does Limos.com work?</h2>
        </div>
        <div className="rio-section-description-container">
          <p className="rio-section-description">
            We search across <span className="bold">12,015+</span> operators to
            find you the <span className="bold">best</span>&nbsp;deals
            <span className="bold">&nbsp;globally</span> on all types
            <br />
            of vehicles. Including Sedans, SUVs, Limos, Vans, Shuttles and Buses
            where <br />
            luxury meets affordability.
          </p>
        </div>
      </div>
    )
  }

  const gallerySection = () => {
    return (
      <div className="gallery-container">
        <div className="gallery-title">
          <h2> Best Prices on Limos.com</h2>
        </div>

        <div className="gallery-image-main-container">
          <div className="gallery-image-container">
            <img
              className="gallery-image"
              src="https://assets.s3.amazonaws.com/images/front-end/new-landing/1.jpg"
              alt="image1"
            />
            <div className="gallery-image-description">
              <p>Tour Transportation</p>
              <p>starting at $119</p>
            </div>
          </div>

          <div className="gallery-image-container">
            <img
              className="gallery-image"
              src="https://assets.s3.amazonaws.com/images/front-end/new-landing/2.jpg"
              alt="image2"
            />
            <div className="gallery-image-description">
              <p>Airport Transfers</p>
              <p>starting at $79</p>
            </div>
          </div>

          <div className="gallery-image-container">
            <img
              className="gallery-image"
              src="https://assets.s3.amazonaws.com/images/front-end/new-landing/3.jpg"
              alt="image3"
            />
            <div className="gallery-image-description">
              <p>Mult-Day Event Transportation</p>
              <p>starting at $199</p>
            </div>
          </div>

          <div className="gallery-image-container">
            <img
              className="gallery-image"
              src="https://assets.s3.amazonaws.com/images/front-end/new-landing/4.jpg"
              alt="image4"
            />
            <div className="gallery-image-description">
              <p>Wedding Transportation</p>
              <p>starting at $149</p>
            </div>
          </div>

          <div className="gallery-image-container">
            <img
              className="gallery-image"
              src="https://assets.s3.amazonaws.com/images/front-end/new-landing/5.jpg"
              alt="image5"
            />
            <div className="gallery-image-description">
              <p>Sports Game Transportation</p>
              <p>starting at $89</p>
            </div>
          </div>

          <div className="gallery-image-container">
            <img
              className="gallery-image"
              src="https://assets.s3.amazonaws.com/images/front-end/new-landing/6.jpg"
              alt="image6"
            />
            <div className="gallery-image-description">
              <p>Luxury Vehicles</p>
              <p>starting at $249</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const OperatorOnboard = () => {
    return (
      <div className="lm-operator-form-container">
        <div className="lm-inner-container">
          <div className="lm-operator-desc">
            <h2>Are you a Chauffeur or Operator?</h2>
            <div className="lm-operator-desc-small">
              Join 12,000+ Operators <br />
              Rapid Onboarding <br />
            </div>
          </div>
          <div className="lm-operator-desc">
            <div className="lm-operator-desc-small">
              Increase Your Revenues & Bookings
              <br /> Reach Customer 24/7 <br /> Any Vehicle Types
            </div>
          </div>
          <div className="lm-operator-btn">
            <img src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Limos.com-Ground-Control.png" />
            <a
              href="https://www.limos.com/a/operator-form"
              className="lm-btn-click mouse-hover-button"
            >
              Become an Operator for Free Today
            </a>
          </div>
        </div>
      </div>
    )
  }
  const pageFooter = () => {
    return (
      <div className="page-footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <FaqListing />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <GoogleMap />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-12 footer-columns">
              <p className="footer-links-heading">Book Now</p>
              <div>
                <ul className="footer-main-links">
                  <li>
                    <a href="tel:8772419318">Call 877-241-9318 for Sales</a>
                  </li>

                  <li>
                    <a href="tel:8778708837">
                      Call 877-870-8837 for Customer Service
                    </a>
                  </li>

                  <li>
                    <a href="tel:8775850797">
                      Call 877-585-0797 for Operator Support
                    </a>
                  </li>

                  <li>
                    <a href="mailto:booking@limos.com">booking@limos.com</a>
                  </li>
                  <li>
                    <a href={routePaths.root}>Book Online &gt; </a>
                  </li>
                  <li>
                    <a href="" onClick={openIntercomeLiveChatWindow}>
                      Live Chat &gt;
                    </a>
                  </li>
                  <li>
                    <Link to={routePaths.siteMap}>SiteMap</Link>
                  </li>
                  <li>
                    <span className="hidden-text">
                      Call 877 590 5193 for additional Sales support
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-4 col-12  footer-columns">
              <p className="footer-links-heading">About</p>
              <div>
                <ul className="footer-main-links">
                  <li>
                    <a href={routePaths.siteTerms}>Terms</a>
                  </li>
                  <li>
                    <a href={routePaths.privacyPolicy}>Privacy</a>
                  </li>
                  <li>
                    <a href="https://www.limos.com/limo-price-guide">
                      Pricing Guide
                    </a>
                  </li>
                  <li>
                    <a href="https://www.limos.com/vehicle-guide">
                      Vehicle Guide
                    </a>
                  </li>
                  <li>
                    <a href="https://www.limos.com/limos-101-pricing-and-planning-guide">
                      Limos 101
                    </a>
                  </li>
                  <li>
                    <a href="https://www.limos.com/customers/faqs">FAQs </a>
                  </li>
                  <li>
                    <a href="https://www.limos.com/operators/join">
                      Partner with Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <SocialLinks />
            </div>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    // fetch("https://staging.limos.com/v1/biz/reservations.json?api_key=GYQPR-cVlSZTzsVzen&result_handle=461771093&pax_first_name=Jim&pax_last_name=Wilson&pax_phone_num=14155672345&pax_email=john@doe.com&payment_pan=4111111111111111&payment_expiration_date=03/2023&payment_postal_code=55555&payment_security_code=123&payment_name=Jim Wilson&payment_street1=123 Smith Road&payment_city=San Francisco&payment_state=CA&pickup_street1=100 broadway street", requestOptions)
    //   .then(response => response.json())
    //   .then(result => console.log(result))
    //   .catch(error => console.log('error', error));
    // fetch('https://staging.limos.com/v1/biz/reservations.json?' + new URLSearchParams({
    //     api_key: 'GYQPR-cVlSZTzsVzen',
    //     result_handle: '461771093',
    //     pax_first_name: 'Jim',
    //     pax_last_name: 'Wilson',
    //     pax_phone_num: testNumber,
    //     pax_email: 'test@gmail.com',
    //     payment_pan: '4111111111111111',
    //     payment_expiration_date: '03/2023',
    //     payment_postal_code: '55555',
    //     payment_security_code: '123',
    //     payment_name: 'Jim Wilson',
    //     payment_street1: '123 Smith Road',
    //     payment_city: 'San Francisco',
    //     payment_state: 'CA',
    //     pickup_street1: '100 broadway street'
    //   }), requestOptions)
    //   .then(response => response.json())
    //   .then(result => console.log(result))
    //   .catch(error => console.log('error', error));
    // var requestOptions = {
    //   method: 'POST',
    //   redirect: 'follow'
    // };
    // var testNumber = "14155672345";
    // var resulthandle = "461773312";
    // var testData = {
    //   api_key: 'GYQPR-cVlSZTzsVzen',
    //   result_handle: resulthandle,
    //   pax_first_name: 'Gloria',
    //   pax_last_name: 'Tester',
    //   pax_phone_num: testNumber,
    //   pax_email: 'gloria@tester.com',
    //   payment_pan: '4111111111111111',
    //   payment_expiration_date: '03/2023',
    //   payment_postal_code: '55555',
    //   payment_security_code: '123',
    //   payment_name: 'Jim Wilson',
    //   payment_street1: '123 Smith Road',
    //   payment_city: 'San Francisco',
    //   payment_state: 'CA',
    //   pickup_street1: '100 broadway street'
    // };
    // console.log("type of ", resulthandle);
    // console.log(testData);
    // fetch('https://staging.limos.com/v1/biz/reservations.json?' + new URLSearchParams(testData), requestOptions)
    // .then(response => response.json())
    // .then(result => console.log(result))
    // .catch(error => console.log('error', error));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setDiscountModal(true)
    }, 3000)
  }, [])

  const validateForm = () => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const emailValid = emailRegex.test(email)

    if (!emailValid) {
      return false
    }

    if (email?.length) {
      if (!emailValid) {
        console.log("email fail")
        return false
      }
    }

    return true
  }

  const handleClose = async () => {
    if (formError) {
      setFormError(false)
    }

    if (validateForm()) {
      try {
        const res = await fetch(
          `https://www.limos.com/v1/biz/reservations/limited_promo_code?api_key=GYQPV-FtfMVNotXtjb&email=${email}`
        )

        const body = await res.json()
        if (body.name) {
          setPromoCodeAPISuccess(true)
        }
      } catch (e) {
        setPromoCodeAPISuccess(false)
      }
      setFormError(false)
    } else {
      setFormError(true)
    }
  }

  return (
    <>
      <div className="banner" style={{ position: "relative" }}>
        <div className="slideshow-container">
          <Carousel
            autoPlay
            showThumbs={false}
            showIndicators={false}
            interval={7000}
            showArrows={true}
          >
            <div className="carousel-slide-content">
              <img
                alt=""
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/limos-new-banner.jpeg"
              />
              <div className="image-shadow"></div>
            </div>
            {/* <div className="carousel-slide-content">
              <img
                alt=""
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/multiple-vehicles-1.jpg"
              />
              <div className="image-shadow"></div>
            </div>
            <div className="carousel-slide-content">
              <img
                alt=""
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/coach.jpg"
              />
              <div className="image-shadow"></div>
            </div>
            <div className="carousel-slide-content">
              <img
                alt=""
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Image3.jpg"
              />
              <div className="image-shadow"></div>
            </div>
            <div className="carousel-slide-content">
              <img
                alt=""
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Image1.jpg"
              />
              <div className="image-shadow"></div>
            </div>
            <div className="carousel-slide-content">
              <img
                alt=""
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Extra-2.jpg"
              />
              <div className="image-shadow"></div>
            </div>
            <div className="carousel-slide-content">
              <img
                alt=""
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Point to point.jpg"
              />
              <div className="image-shadow"></div>
            </div> */}
          </Carousel>
          <div className="slider-text-container mobile">
            <div className="form-luxury-image">
              {/* <p className="book-online-text">Book Now</p> */}
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Luxury-Fleet-Vehicle.png"
                alt="Luxury-Fleet-Vehicle.png"
              />
            </div>
            {/* <div className="text-container">
              <p className="title-text">
                Luxury Sedans, SUVs, Limos, Vans, <br />
                Shuttles & Buses
              </p>
              <p className="divider-title-text"></p>
              <p className="title-text">
                Airport Transfers to Large <br />
                Scale Events
              </p>
              <p className="divider-title-text"></p>
              <p className="subtitle-text">Over 32 Years in Business</p>
              <p className="divider-title-text"></p>
              <p className="subtitle-text">
                1,797+ Reviews
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Star.png"
                  alt="star"
                  style={{ height: "25px", width: "25px" }}
                />
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Star.png"
                  alt="star"
                  style={{ height: "25px", width: "25px" }}
                />
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Star.png"
                  alt="star"
                  style={{ height: "25px", width: "25px" }}
                />
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Star.png"
                  alt="star"
                  style={{ height: "25px", width: "25px" }}
                />
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Star2.png"
                  alt="star"
                  style={{ height: "25px", width: "25px" }}
                />
              </p>
            </div> */}
            {showText && (
              <div className="promo messages">
                <div className="message last">
                  <span
                    className="type"
                    style={{
                      "--n": 200,
                      letterSpacing: "normal",
                    }}
                  >
                    Congratulations! You just received 20% off on <br />
                    your next order! -Steve from Team Limos
                    {/* Congratulations! Take 20% off on all
                        <br /> bookings! -Steve from Team Limos */}
                  </span>
                </div>
              </div>
            )}
            <div id="mobile-contact-buttons">{btnContainerMobile()}</div>
          </div>
          <div className="banner-text-container">
            <div className="slider-text-container desktop">
              <p className="text logo-text">
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Luxury-Fleet-Vehicle.png"
                  style={{ height: 100 }}
                  alt="Luxury-Fleet-Vehicle.png"
                />
              </p>
              {/* <p className="text logo-text">
                <img
                  src={label.logo}
                  alt="white logo limos.com text"
                  style={{ height: 35 }}
                />
              </p> */}
              {/*  <p className="slider-text-container-title">
                Searching Over 12,015 Operators to find you the Best Deals
              </p> */}
              <p className="slider-text-container-pre-title">
                Luxury Sedans, SUVs, Limos, Vans, Shuttles & Buses
                <br />
                Over 32 Years in Business
                <br />
                1,797+ Reviews
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Star.png"
                  alt="star"
                  style={{ height: "25px", width: "25px" }}
                />
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Star.png"
                  alt="star"
                  style={{ height: "25px", width: "25px" }}
                />
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Star.png"
                  alt="star"
                  style={{ height: "25px", width: "25px" }}
                />
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Star.png"
                  alt="star"
                  style={{ height: "25px", width: "25px" }}
                />
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Star2.png"
                  alt="star"
                  style={{ height: "25px", width: "25px" }}
                />
                {showText && (
                  <div className="promo messages">
                    <div className="message last">
                      <span
                        className="type"
                        style={{
                          "--n": 200,
                          letterSpacing: "normal",
                        }}
                      >
                        Congratulations! You just received 20% off on <br />
                        your next order! -Steve from Team Limos
                        {/* Congratulations! Take 20% off on all
                        <br /> bookings! -Steve from Team Limos */}
                      </span>
                    </div>
                  </div>
                )}
              </p>

              {/* <div className="cta-buttons-wrapper">
                <div className="call-btn">
                  <a href="tel:8667300524" className="banner-call-for-discount">
                    📞 Call 866 730 0524 for Special
                    <span className="additional-offer">
                      10% OFF 5+ HRS SPECIAL!
                    </span>
                  </a>
                  <button
                    className="book-online-now"
                    onClick={hideRequestFormToggle}
                  >
                    
                    📋 Request a Quote
                  </button>
                </div>
              </div> */}
            </div>
            <div className="form-luxury-image">
              {/* <p className="book-online-text">Book Now</p> */}
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Luxury-Fleet-Vehicle.png"
                alt="Luxury-Fleet-Vehicle.png"
              />
            </div>
            <div className="book-online-mobile"></div>

            <div className="banner-form-container">
              <div className="existing-search-form">
                <NewSearchForm
                  pickupPlace={props.pickupPlace}
                  dropoffPlace={props.dropoffPlace}
                  setPlace={props.setPlace}
                  setVehicleOptions={props.setVehicleOptions}
                  partySize={props.partySize}
                  vehicleTypes={props.vehicleTypes}
                  pickupPlaceItem={props.pickupPlaceItem}
                  onPlaceQuery={props.onPlaceQuery}
                  pickupPlaceQueryResults={props.pickupPlaceQueryResults}
                  dropoffPlaceQueryResults={props.dropoffPlaceQueryResults}
                  tripWizardValues={props.tripWizardValues}
                  setSearchFormErrors={props.setSearchFormErrors}
                  searchFormErrors={props.searchFormErrors}
                  completeDate={props.completeDate}
                  returnDate={props.returnDate}
                  finishedTripWizard={props.finishedTripWizard}
                  createInitialSearchFormErrorState={
                    props.createInitialSearchFormErrorState
                  }
                  openChatWindow={props.openChatWindow}
                  errors={props.searchFormErrors}
                  setError={props.setError}
                  onSearchFormChange={props.onSearchFormChange}
                  searchForm={props.searchForm}
                  setSearchParams={props.setSearchParams}
                  roundTrip={props.roundTrip}
                  setRoundTrip={props.setRoundTrip}
                  setCustomPartySize={props.setCustomPartySize}
                  customPartySize={props.customPartySize}
                  images={[]}
                  formTitle={"Searching 112,000 Vehicles for Best Deals"}
                  formSubtitle={searchFormSubtitle}
                  formButtonText={"Search Now to get 20% off!"}
                  classFlag={isActive}
                />
                <div className="video-container-mobile">
                  <div className="video-container-mobile-title">
                    How It Works
                  </div>
                  <video
                    width="100%"
                    height="300px"
                    controls
                    style={{ borderRadius: "5px" }}
                    poster="https://assets.s3.amazonaws.com/images/front-end/WelcometoLimos.png"
                  >
                    <source
                      src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Welcome+to+Limos.com.MP4"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className="down-slider-text-container mobile">
                  <div className="text-container">
                    <p className="title-text">
                      Luxury Sedans, SUVs, Limos, Vans, <br />
                      Shuttles & Buses
                    </p>
                    <p className="divider-title-text"></p>
                    <p className="title-text">
                      Airport Transfers to Large <br />
                      Scale Events
                    </p>
                    <p className="divider-title-text"></p>
                    <p className="subtitle-text">Over 32 Years in Business</p>
                    <p className="divider-title-text"></p>
                    <p className="subtitle-text">
                      1,797+ Reviews
                      <img
                        src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Star.png"
                        alt="star"
                        style={{ height: "25px", width: "25px" }}
                      />
                      <img
                        src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Star.png"
                        alt="star"
                        style={{ height: "25px", width: "25px" }}
                      />
                      <img
                        src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Star.png"
                        alt="star"
                        style={{ height: "25px", width: "25px" }}
                      />
                      <img
                        src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Star.png"
                        alt="star"
                        style={{ height: "25px", width: "25px" }}
                      />
                      <img
                        src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Star2.png"
                        alt="star"
                        style={{ height: "25px", width: "25px" }}
                      />
                    </p>
                  </div>
                </div>
              </div>

              <div className="video-container">
                <div
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontFamily: "SFCompactDisplay-Medium",
                    marginBottom: "5px",
                  }}
                >
                  How It Works
                </div>
                <video
                  width="100%"
                  height="100%"
                  controls
                  style={{ borderRadius: "5px" }}
                >
                  <source
                    src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Welcome+to+Limos.com.MP4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>

        {btnContainer()}
      </div>

      {/*       {btnContainer()}
       */}
      {newSpecializeSection()}

      {specializeSection()}

      {rioSection()}

      {fourFeatures()}

      {gallerySection()}

      {OperatorOnboard()}

      {/* Review Section  hardcode one*/}
      {reviewSection()}

      {pageFooter()}

      <Modal
        className="discount-popup-model-container"
        show={discountModal}
        onHide={() => setDiscountModal(false)}
        style={{ top: "20%" }}
      >
        <Modal.Body>
          <div className="discount-modal-img-container">
            <img
              alt="alt img"
              src="https://assets.s3.amazonaws.com/images/front-end/new-landing/PromoPopup.jpg"
              style={{ height: "170px", width: "100%", borderRadius: "5px" }}
            />
            <Modal.Header closeButton onClick={closeModal}>
              <Modal.Title></Modal.Title>
            </Modal.Header>
          </div>
          {!promoCodeAPISuccess && (
            <>
              <div className="discount-modal-description">
                <p className="discount-modal-title">
                  Get an additional 10% off now!*
                </p>
                <p className="discount-modal-sub-title">
                  We’ll send you a promo code to your email below.
                </p>
              </div>

              <Form className="discount-modal-form">
                <input
                  className="input-type-email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  type="email"
                  placeholder="Enter Email"
                  aria-label="Email"
                  style={{ width: "100%", margin: "8px 0", padding: 8 }}
                />

                {formError && (
                  <p style={{ color: "red" }}>
                    Please enter a valid email address.
                  </p>
                )}
              </Form>

              <div className="discount-modal-submit-btn-container">
                <Button onClick={handleClose} variant="secondary">
                  Submit
                </Button>
              </div>

              <div className="discount-modal-footer-text-container">
                <p className="footer-text">
                  By submitting this form, you agree to receive email marketing
                  emails from Limos.com and its partners. You can unsubscribe at
                  any time. *Some restrictions apply. Limited time only.
                </p>
              </div>
            </>
          )}
          {promoCodeAPISuccess && (
            <>
              <div className="discount-modal-description">
                <p className="discount-modal-title">Great, check your email!</p>
                <p className="discount-modal-sub-title success-text">
                  We’ve emailed you the promo code. Valid for 24 hrs only. Make
                  sure to check your junk folder just in case.
                </p>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        className="popup-model-container"
        show={modalShown}
        onHide={() => setModalShown(false)}
      >
        <Modal.Body>
          <div className="popup-close-button-container">
            <p className="popup-close-button">
              <button onClick={hideRequestFormToggle}> Close </button>
            </p>
          </div>

          <div className="request-a-quote-form-container">
            <NewSearchForm
              pickupPlace={props.pickupPlace}
              dropoffPlace={props.dropoffPlace}
              setPlace={props.setPlace}
              setVehicleOptions={props.setVehicleOptions}
              partySize={props.partySize}
              vehicleTypes={props.vehicleTypes}
              pickupPlaceItem={props.pickupPlaceItem}
              onPlaceQuery={props.onPlaceQuery}
              pickupPlaceQueryResults={props.pickupPlaceQueryResults}
              dropoffPlaceQueryResults={props.dropoffPlaceQueryResults}
              tripWizardValues={props.tripWizardValues}
              setSearchFormErrors={props.setSearchFormErrors}
              searchFormErrors={props.searchFormErrors}
              completeDate={props.completeDate}
              returnDate={props.returnDate}
              finishedTripWizard={props.finishedTripWizard}
              createInitialSearchFormErrorState={
                props.createInitialSearchFormErrorState
              }
              openChatWindow={props.openChatWindow}
              errors={props.searchFormErrors}
              setError={props.setError}
              onSearchFormChange={props.onSearchFormChange}
              searchForm={props.searchForm}
              setSearchParams={props.setSearchParams}
              roundTrip={props.roundTrip}
              setRoundTrip={props.setRoundTrip}
              setCustomPartySize={props.setCustomPartySize}
              customPartySize={props.customPartySize}
              images={[]}
              formTitle={"Request a Quote"}
              formSubtitle={searchFormSubtitle}
              isRequestAQuoteForm={true}
              formButtonText={"Submit"}
            />

            {/* <form ref={form} className="getin-touch-form form" autoComplete="off" onSubmit={formik.handleSubmit}>
                <div className="form-item">
                  <input type="text" placeholder="Name*" className="form-input" name="name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name}/>
                </div>
                {formik.touched.name && formik.errors.name ? (
                  <p className="form-error-description">{formik.errors.name}</p>
                ) : null}
                <div className="form-item">
                  <input type="text" placeholder="Email*" className="form-input" name="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email}/>
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <p className="form-error-description">{formik.errors.email}</p>
                ) : null}
                <div className="form-item">
                  <input type="text" placeholder="Phone*" className="form-input" name="phone" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phone}/>
                </div>
                {formik.touched.phone && formik.errors.phone ? (
                  <p className="form-error-description">{formik.errors.phone}</p>
                ) : null}

                <div className="form-item">
                  <input type="date" placeholder="Date*" className="form-input" name="date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.date}/>
                </div>
                {formik.touched.date && formik.errors.date ? (
                  <p className="form-error-description">{formik.errors.date}</p>
                ) : null}

                <div className="form-item">
                  <input type="text" placeholder="No. of passengers" className="form-input" name="noofpassengers" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.noofpassengers}/>
                </div>
                {formik.touched.noofpassengers && formik.errors.noofpassengers ? (
                  <p className="form-error-description">{formik.errors.noofpassengers}</p>
                ) : null}
                <div className="form-item">
                  <input type="text" placeholder="city/location*" className="form-input" name="citylocation" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.citylocation}/>
                </div>
                {formik.touched.citylocation && formik.errors.citylocation ? (
                  <p className="form-error-description">{formik.errors.citylocation}</p>
                ) : null}
                <div className="form-item">
                  <input type="text" placeholder="occasion/notes" className="form-input" name="occasionnotes" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.occasionnotes}/>
                </div>
                {formik.touched.occasionnotes && formik.errors.occasionnotes ? (
                  <p className="form-error-description">{formik.errors.occasionnotes}</p>
                ) : null}
                <div className="form-item google-recaptcha">
                  <ReCAPTCHA
                    sitekey="6LeIYNMfAAAAADZUqEBTV-j2ViNc5K31ZW6HpFWN"
                    onChange={handleSetRecaptcha}
                  />
                </div>
                {formik.errors.gcaptcha ? (
                  <p className="form-error-description">{formik.errors.gcaptcha}</p>
                ) : null}
                <div className="form-submit-button">
                  <button type="submit">Submit</button>
                </div>
            </form> */}
          </div>
        </Modal.Body>
      </Modal>

      {/* <section className="testimonial">
      <div className="premiumvehicles">
        <div className="vote-image">
            <img src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Luxury-Fleet.jpg" />
        </div>
        <div className="premiumtext">
          <p>Premium vehicles for business, leisure, and special events.</p>
        </div>
        <div className="call-btn">
            <div className="horizontal-center">
              <a href="tel:8667300524" className="slider-call-for-discount banner-call-for-discount">
                  📞 Call 866 730 0524 for Special
                  <span className="additional-offer">10% OFF 5+ HRS SPECIAL!</span>
              </a>
            </div>
        </div>
      </div>
    </section> */}
      {/* <section className="second-slider">
      <Carousel autoPlay showThumbs = {false} showIndicators = {false}>
        <div>
          <img alt="" src="https://assets.s3.amazonaws.com/images/front-end/new-landing/multiple-vehicles-1.jpg" />
          <div className="slider-content">
            <div className="call-btn">
                <div className="horizontal-center">
                  <a href="tel:8667300524" className="second-fold-call-for-discount banner-call-for-discount">
                      📞 Call 866 730 0524 for Special
                      <span className="additional-offer">10% OFF 5+ HRS SPECIAL!</span>
                  </a>
                </div>
            </div>
          </div>
        </div>
        <div>
          <img alt="" src="https://assets.s3.amazonaws.com/images/front-end/new-landing/coach.jpg" />
          <div className="slider-content">
            <div className="call-btn">
                  <div className="horizontal-center">
                    <a href="tel:8667300524" className="second-fold-call-for-discount banner-call-for-discount">
                      📞 Call 866 730 0524 for Special
                        <span className="additional-offer">10% OFF 5+ HRS SPECIAL!</span>
                    </a>
                  </div>
              </div>
          </div>
        </div>
        <div>
          <img alt="" src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Image3.jpg" />
          <div className="slider-content">
            <div className="call-btn">
                <div className="horizontal-center">
                  <a href="tel:8667300524" className="second-fold-call-for-discount banner-call-for-discount">
                      📞 Call 866 730 0524 for Special
                      <span className="additional-offer">10% OFF 5+ HRS SPECIAL!</span>
                  </a>
                </div>
            </div>
          </div>
        </div>
        <div>
          <img alt="" src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Image1.jpg" />
          <div className="slider-content">
            <div className="call-btn">
                <div className="horizontal-center">
                  <a href="tel:8667300524" className="second-fold-call-for-discount banner-call-for-discount">
                      📞 Call 866 730 0524 for Special
                      <span className="additional-offer">10% OFF 5+ HRS SPECIAL!</span>
                  </a>
                </div>
            </div>
          </div>
        </div>
        <div>
          <img alt="" src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Extra-2.jpg" />
          <div className="slider-content">
            <div className="call-btn">
                <div className="horizontal-center">
                  <a href="tel:8667300524" className="second-fold-call-for-discount banner-call-for-discount">
                      📞 Call 866 730 0524 for Special
                      <span className="additional-offer">10% OFF 5+ HRS SPECIAL!</span>
                  </a>
                </div>
            </div>
          </div>
        </div>
        <div>
          <img alt="" src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Point to point.jpg" />
          <div className="slider-content">
            <div className="call-btn">
                <div className="horizontal-center">
                  <a href="tel:8667300524" className="second-fold-call-for-discount banner-call-for-discount">
                      📞 Call 866 730 0524 for Special
                      <span className="additional-offer">10% OFF 5+ HRS SPECIAL!</span>
                  </a>
                </div>
            </div>
          </div>
        </div>
      </Carousel>
    </section> */}
    </>
  )
}

export default IndexPage
